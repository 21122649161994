import React from 'react';
import { Box, Typography, Container } from '@mui/material';
import { useLanguage } from '../LanguageContext';
import translations from '../translations';
import { useEffect } from 'react';

const Landing = () => {
  const { language } = useLanguage();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (

    <Box>
      <Container maxWidth="lg">
        <Box sx={{
          marginTop: '80px',  // Keep this for navbar spacing
          pt: { xs: 10, sm: 15, md: 20, lg: 30 }  // Keep your original values to see the title
        }}>
          {/* Main Title */}
          <Typography variant="h1" sx={{
            fontWeight: 'bold',
            fontSize: { xs: '1.75rem', sm: '2.5rem', md: '3rem', lg: '3rem' },
            textAlign: 'center',
            color: '#ffffff',

          }}>
            {translations[language]['Crh1']}
          </Typography>

          {/* Image Box */}

          <Box
            sx={{
              position: 'relative',
              width: '100%',
              height: {
                xs: '60vh',
                sm: '70vh',
                md: '80vh',
                lg: '70vh'  // Reduced from 90vh to prevent cutting
              },
              backgroundImage: `url('/images/Crhcards.webp')`,
              backgroundSize: 'contain',  // Changed from '100%' to 'contain'
              backgroundPosition: 'center',
              backgroundRepeat: 'no-repeat',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              mt: { xs: 1, sm: 1, md: 1, lg: 1 },
              mb: { xs: 1, sm: 1, md: 1, lg: 1 }
            }}
          />
        </Box>

        <Box sx={{color: '#ffffff'}}>

          {/* Welcome Section */}
          <Box sx={{ mb: 8 }}>
            <Typography variant="h2" sx={{
              fontSize: { xs: '1.5rem', sm: '1.75rem', md: '2rem', lg: '2rem' },
              mb: 3,
              textAlign: 'center',
              color: '#ffffff'
            }}>
              {translations[language]['Crh2']}

            </Typography>
            <Typography variant="h2" sx={{
              fontSize: { xs: '1.5rem', sm: '1.75rem', md: '2rem', lg: '2rem' },
              mb: 3,
              fontWeight: 'bold',
              textAlign: 'center',
              color: '#ffffff'
            }}>

              {translations[language]['Crh22']}
            </Typography>

            <Typography variant="body1" sx={{
              fontSize: { xs: '1.1rem', sm: '1.2rem', md: '1.3rem', lg: '1.3rem' },
              mb: 4,
              textAlign: 'center',
              color: '#e0e0e0'
            }}>
              {translations[language]['Crh3']}
            </Typography>

            <Typography variant="body1" sx={{
              fontSize: { xs: '1.1rem', sm: '1.2rem', md: '1.3rem', lg: '1.3rem' },
              mb: 4,
              textAlign: 'center',
              color: '#e0e0e0'
            }}>
              {translations[language]['Crh4']}
            </Typography>
          </Box>

          {/* Changes Section */}
          <Box sx={{
            mb: 8,
            p: 4,
            backgroundColor: '#ffffff',
            borderRadius: '10px'
          }}>
            <Typography variant="h2" sx={{
              fontSize: { xs: '1.75rem', sm: '2rem', md: '2.25rem' },
              mb: 4,
              textAlign: 'center',
              fontWeight: 'bold',
              color: '#000000'
            }}>
              {translations[language]['Crh5']}
            </Typography>

            <Box sx={{ mb: 3 }}>
              <Typography sx={{
                fontSize: { xs: '1.2rem', sm: '1.3rem', md: '1.4rem' },
                color: '#000000'
              }}>
                <strong>{translations[language]['Crh61']}</strong>
                {translations[language]['Crh62']}
              </Typography>
            </Box>

            <Box sx={{ mb: 3 }}>
              <Typography sx={{
                fontSize: { xs: '1.2rem', sm: '1.3rem', md: '1.4rem' },
                color: '#000000'
              }}>
                <strong>{translations[language]['Crh71']}</strong>
                {translations[language]['Crh72']}
              </Typography>
            </Box>

            <Box sx={{ mb: 3 }}>
              <Typography sx={{
                fontSize: { xs: '1.2rem', sm: '1.3rem', md: '1.4rem' },
                color: '#000000'
              }}>
                <strong>{translations[language]['Crh81']}</strong>
                {translations[language]['Crh82']}
              </Typography>
            </Box>
          </Box>


          <Box sx={{
            mb: 8,
            p: 4,
            backgroundColor: '#ffffff',
            borderRadius: '10px'
          }}>
            {/* Title */}
            <Typography variant="h2" sx={{
              fontSize: { xs: '1.75rem', sm: '2rem', md: '2.25rem' },
              mb: 4,
              textAlign: 'center',
              fontWeight: 'bold',
              color: '#000000'
            }}>
              {translations[language]['Crh9']}
            </Typography>

            {/* Introduction text */}
            <Box sx={{ mb: 4 }}>
              <Typography sx={{
                fontSize: { xs: '1.2rem', sm: '1.3rem', md: '1.4rem' },

                color: '#000000'
              }}>
                {translations[language]['Crh90']}
              </Typography>
            </Box>

            {/* Transparency and Security */}
            <Box sx={{ mb: 3 }}>
              <Typography sx={{
                fontSize: { xs: '1.2rem', sm: '1.3rem', md: '1.4rem' },
                color: '#000000'
              }}>
                <strong>{translations[language]['Crh91']}</strong>
                {translations[language]['Crh92']}
              </Typography>
            </Box>

            {/* No impact on contracts */}
            <Box sx={{ mb: 3 }}>
              <Typography sx={{
                fontSize: { xs: '1.2rem', sm: '1.3rem', md: '1.4rem' },
                color: '#000000'
              }}>
                <strong>{translations[language]['Crh101']}</strong>
                {translations[language]['Crh102']}
              </Typography>
            </Box>

            {/* Right of withdrawal */}
            <Box sx={{ mb: 3 }}>
              <Typography sx={{
                fontSize: { xs: '1.2rem', sm: '1.3rem', md: '1.4rem' },
                color: '#000000'
              }}>
                <strong>{translations[language]['Crh111']}</strong>
                {translations[language]['Crh112']}
              </Typography>
            </Box>

            {/* Transparent communications */}
            <Box sx={{ mb: 3 }}>
              <Typography sx={{
                fontSize: { xs: '1.2rem', sm: '1.3rem', md: '1.4rem' },
                color: '#000000'
              }}>
                <strong>{translations[language]['Crh121']}</strong>
                {translations[language]['Crh122']}
              </Typography>
            </Box>
          </Box>


          <Box sx={{ mb: 8 }}>

            <Typography variant="body1" sx={{
              fontSize: { xs: '1.1rem', sm: '1.2rem', md: '1.3rem', lg: '1.3rem' },
              mb: 4,
              textAlign: 'center',
              color: '#e0e0e0'
            }}>
              {translations[language]['Crh17']}
            </Typography>

            <Typography variant="body1" sx={{
              fontSize: { xs: '1.1rem', sm: '1.2rem', md: '1.3rem', lg: '1.3rem' },
              mb: 4,
              textAlign: 'center',
              color: '#e0e0e0'
            }}>
              {translations[language]['Crh18']}
            </Typography>
          </Box>

        </Box>
      </Container>
    </Box>
  );
};

export default Landing;