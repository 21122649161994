import React, { useState } from 'react';
import { AppBar, Toolbar, Button, IconButton, useMediaQuery, MenuItem, useTheme, Box, Typography, Divider, Drawer } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useNavigate } from 'react-router-dom';
import { useLanguage } from '../LanguageContext';
import translations from '../translations';

const Navbar = () => {
    const navigate = useNavigate();
    const { language, switchLanguage } = useLanguage();

    const toggleLanguage = () => {
        switchLanguage(language === 'en' ? 'it' : 'en');
        handleMobileMenuClose();
    };

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));
    const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

    const handleMobileMenuOpen = () => {
        setMobileMenuOpen(true);
    };

    const handleMobileMenuClose = () => {
        setMobileMenuOpen(false);
    };

    const handleNavigate = (path) => {
        navigate(path);
        handleMobileMenuClose();
    };

    const getLanguageFlag = () => (
        language === 'en' ? (
            <img src="/images/Usa.svg" alt="USA Flag" style={{ width: '45px', height: '30px' }} />
        ) : (
            <img src="/images/Ita.svg" alt="Italian Flag" style={{ width: '45px', height: '30px' }} />
        )
    );

    const handleApriIlConto = () => {
        if (isMobile) {
            const isAndroid = /android/i.test(navigator.userAgent);
            const isIOS = /iphone|ipad|ipod/i.test(navigator.userAgent);

            if (isAndroid) {
                window.location.href = 'carta://';
                setTimeout(() => {
                    window.location.href = 'https://play.google.com/store/apps/details?id=com.cartareale.app&hl=en_NZ&gl=US&pli=1';
                }, 500);
            } else if (isIOS) {
                window.location.href = 'carta://';
                setTimeout(() => {
                    window.location.href = 'https://apps.apple.com/us/app/carta-reale/id6450711819';
                }, 500);
            }
        } else {
            window.open('https://my.cartareale.it/register', '_blank');
        }
    };

    const menuItems = [
        { path: '/', labelKey: 'home' },
        { path: '/about', labelKey: 'about_us' },
        { path: '/cards', labelKey: 'cards' },
        { path: '/landing', labelKey: 'landing' },
        { path: '/contact_us', labelKey: 'contacts' },
    ];

    const dropdownMenuItems = [
        ...menuItems,
        { path: '/articles', labelKey: 'Apri Il conto' },
        { path: '/baas', labelKey: 'Accesso clienti' },
    ];

    return (
        <Box sx={{
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
            height: { xs: '120px', sm: '120px', md: '160px', lg: '160px' },
            backgroundColor: '#000000',
            zIndex: (theme) => theme.zIndex.appBar,
        }}>
            <Box sx={{
                maxWidth: '1920px',
                width: '100%',
                height: '100%',
                margin: '0 auto',
                position: 'relative',
            }}>
                <AppBar
                    position="static"
                    elevation={0}
                    sx={{
                        backgroundImage: "url('/images/Navbar1.svg')",
                        backgroundSize: { xs: '220px', sm: '220px', md: '280px', lg: '280px' },
                        backgroundPosition: 'center',
                        backgroundRepeat: 'no-repeat',
                        backgroundColor: 'transparent',
                        height: '100%',
                        color: '#FFFFFF',
                        paddingBottom: '10px',
                    }}
                >
                    <Toolbar sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', alignItems: 'center', height: '100%' }}>
                        {useMediaQuery(theme.breakpoints.down('md')) ? (
                            <>
                                <Box sx={{ display: 'flex', justifyContent: 'flex-end', width: '100%', alignItems: 'center', height: '100%' }}>
                                    <IconButton
                                        size="large"
                                        edge="start"
                                        color="inherit"
                                        aria-label="menu"
                                        sx={{ mr: 2 }}
                                        onClick={handleMobileMenuOpen}
                                    >
                                        <MenuIcon />
                                    </IconButton>
                                </Box>
                                <Drawer
                                    anchor="right"
                                    open={mobileMenuOpen}
                                    onClose={handleMobileMenuClose}
                                    PaperProps={{
                                        style: {
                                            width: '100%',
                                            maxWidth: '100%',
                                            backgroundColor: '#000000',
                                        },
                                    }}
                                >
                                    <Box sx={{ py: 2, display: 'flex', alignItems: 'center' }}>
                                        <IconButton
                                            onClick={handleMobileMenuClose}
                                            sx={{ ml: 1, color: '#FFFFFF' }}
                                        >
                                            <ArrowBackIcon />
                                        </IconButton>
                                        <Typography variant="h6" sx={{ ml: 2, color: '#FFFFFF' }}>
                                            Menu
                                        </Typography>
                                    </Box>
                                    <Divider sx={{ backgroundColor: '#FFFFFF' }} />
                                    <Box sx={{ py: 2 }}>
                                        {dropdownMenuItems.map(({ path, labelKey }) => (
                                            <MenuItem
                                                key={labelKey}
                                                onClick={() => {
                                                    if (path === '/articles') {
                                                        handleApriIlConto();
                                                    } else if (path === '/baas') {
                                                        window.open('https://my.cartareale.it', '_blank');
                                                    } else {
                                                        handleNavigate(path);
                                                    }
                                                    handleMobileMenuClose();
                                                }}
                                                sx={{
                                                    color: '#FFFFFF',
                                                    fontSize: '1rem',
                                                    textTransform: 'none',
                                                    fontWeight: '400',
                                                    whiteSpace: 'nowrap',
                                                    py: 1,
                                                }}
                                            >
                                                {translations[language][labelKey] || labelKey}
                                            </MenuItem>
                                        ))}
                                        <Divider sx={{ backgroundColor: '#FFFFFF' }} />
                                        <MenuItem
                                            onClick={toggleLanguage}
                                            sx={{
                                                backgroundColor: 'transparent',
                                                padding: 0,
                                                minWidth: 'unset',
                                                display: 'flex',
                                                justifyContent: 'center',
                                                my: 1,
                                            }}
                                        >
                                            {getLanguageFlag()}
                                        </MenuItem>
                                    </Box>
                                </Drawer>
                            </>
                        ) : (
                            <>
                                <Box display="flex" alignItems="center" justifyContent="flex-end" sx={{ width: '100%' }}>
                                    <Box
                                        display="flex"
                                        alignItems="center"
                                        onClick={handleApriIlConto}
                                        sx={{ cursor: 'pointer', marginRight: '20px' }}
                                    >
                                        <img
                                            src="/images/Navbar2.svg"
                                            alt="Apri Il conto"
                                            style={{ width: '30px', height: '30px', marginRight: '10px', alignSelf: 'flex-end' }}
                                        />
                                        <Typography variant="body1" sx={{ color: '#FFFFFF', whiteSpace: 'nowrap', fontSize: '1rem' }}>
                                            {translations[language]['Apri il conto']}
                                        </Typography>
                                    </Box>
                                    <Box
                                        display="flex"
                                        alignItems="center"
                                        onClick={() => window.open('https://my.cartareale.it', '_blank')}
                                        sx={{ cursor: 'pointer', marginRight: '20px' }}
                                    >
                                        <img
                                            src="/images/Navbar3.svg"
                                            alt="Apri Il conto"
                                            style={{ width: '45px', height: '45px', marginRight: '10px', alignSelf: 'flex-end' }}
                                        />
                                        <Typography variant="body1" sx={{ color: '#FFFFFF', whiteSpace: 'nowrap', fontSize: '1rem' }}>
                                            {translations[language]['Accesso clienti']}
                                        </Typography>
                                    </Box>
                                </Box>
                                <Box display="flex" alignItems="center" justifyContent="center" sx={{ width: '100%', marginTop: isMobile ? '10px' : isSmallScreen ? '20px' : '30px' }}>
                                    {menuItems.map(({ path, labelKey }) => (
                                        <Button
                                            key={labelKey}
                                            onClick={() => handleNavigate(path)}
                                            sx={{
                                                color: '#FFFFFF',
                                                '&:hover': { color: 'pink' },
                                                marginLeft: { xs: '10px', sm: '10px', md: '30px', lg: '30px' },
                                                marginRight: { xs: '10px', sm: '10px', md: '30px', lg: '30px' },
                                                fontSize: isSmallScreen ? '0.7rem' : '1rem',
                                                padding: isSmallScreen ? '2px 10px' : '2px 60px',
                                                textTransform: 'none',
                                                fontWeight: '400',
                                                whiteSpace: 'nowrap',
                                            }}
                                        >
                                            {translations[language][labelKey]}
                                        </Button>
                                    ))}
                                    <Button
                                        onClick={toggleLanguage}
                                        variant="contained"
                                        sx={{
                                            backgroundColor: 'transparent',
                                            padding: 0,
                                            minWidth: 'unset',
                                            marginLeft: isSmallScreen ? '20px' : '100px',
                                        }}
                                    >
                                        {getLanguageFlag()}
                                    </Button>
                                </Box>
                            </>
                        )}
                    </Toolbar>
                </AppBar>
            </Box>
        </Box>
    );
    
};

export default Navbar;